window.addEventListener('load', () => {
	$('.grid').isotope({
		itemSelector: '.grid-item',
		percentPosition: true,
		originLeft: true,
		originTop: true,
		masonry: {
			// use outer width of grid-sizer for columnWidth
			columnWidth: '.grid-sizer',
		},
	});
});

window.addEventListener('DOMContentLoaded', () => {
	const contactus = document.querySelector('.contactus');
	if (contactus) {
		contactus.addEventListener('click', () => {
			$('.modal.contact').fadeIn('fast');
			$('.err').html('');
		});
	}
	const close = document.querySelector('.modal.contact .close');
	if (close) {
		close.addEventListener('click', () => {
			$('.modal.contact').fadeOut('fast');
		});
	}

	const menumobiletoggle = document.querySelector('.menumobiletoggle');
	const sidepanel = document.querySelector('.sidepanel');

	menumobiletoggle.addEventListener('click', () => {
		sidepanel.classList.toggle('open');
		menumobiletoggle.classList.toggle('open');
	});
});
